@import '../../scss/base';

.homecard-map {
  max-width: 440px;
  margin: auto auto 0;
  padding: $spacing-xxl $spacing-m $spacing-l;
  background-color: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  position: relative;

  &__placeholder {
    background-color: white;
    width: 100%;
    height: 100px;
    @include from-tablet {
      height: 120px;
    }

    svg {
      height: 100px;
      @include from-tablet {
        height: 120px;
      }
    }
  }

  &__close {
    fill: #1C1B1F;
    position: absolute;
    right: $spacing-l;
    top: $spacing-m;
  }
}