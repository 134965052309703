@import '@spotahome/marketplace-common/src/scss/base';

.value-proposition {
  &__bullet-point {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-xxs;
    min-height: 18px;

    svg {
      margin-right: 0;
    }

    span {
      text-align: left;
    }

    & > * + * {
      margin-left: $spacing-m;
    }

    @include from-tablet {
      flex: initial;
      justify-content: center;
      margin-bottom: 0;

      svg {
        margin-right: $spacing-s;
      }
    }

    @include from-laptop {
      min-height: 36px;
    }

    @include from-desktop {
      min-height: 18px;
    }

    &__text--original {
      font-size: $font-s;
      min-width: 130px;
    }

    &__subtext {
      font-size: $font-s;
      font-family: 'PoppinsLight';
    }

    &__icon{
      min-width: 24px;

      &:before {
        font-size: 20px;
      }

      &--faster {
        &:before {
          font-size: 18px;
        }
      }
    }
  }

  &__close {
    position: static;
    top: 0;
    right: 0;
    align-self: flex-start;

    @include from-tablet {
      margin-left: 5%;
      align-self: center;
    }
  }
}
