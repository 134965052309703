@import '../../scss/base';

.desktop-map-homecard {
  max-width: 70%;
  @include from-wide {
    max-width: 50%;
  }
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 20%;
  padding: $spacing-xxl $spacing-m $spacing-l;
  background-color: white;
  border-radius: 16px;

  &__placeholder {
    background-color: white;
    width: 100%;
    height: 100px;
    @include from-tablet {
      height: 120px;
    }

    svg {
      height: 100px;
      @include from-tablet {
        height: 120px;
      }
    }
  }

  &__close {
    fill: #1C1B1F;
    position: absolute;
    right: $spacing-l;
    top: $spacing-m;
  }
}