@import '@spotahome/marketplace-common/src/scss/base';

.map-placeholder {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;

    &--blur {
      filter: blur(2px);
    }

    &--desaturated {
      filter: grayscale(.6);
    }
  }

  button {
    @include font-bold;
    z-index: 2;
  }

  &__tenants-banner {
    position: absolute;
    left: $spacing-m;
    top: $spacing-l;
    z-index: 2;
  }
}
