@import '@spotahome/marketplace-common/src/scss/base';

.tenants-banner {
  display: none;
  @include from-laptop {
    display: flex;
    align-items: center;
    gap: $spacing-xxs;
    @include font-bold;
    @include font-xs;
    width: max-content;
    text-transform: uppercase;
    margin: 0;
    background-color: $secondary-blue-light;
    padding: $spacing-xs $spacing-m;
    border-radius: $spacing-m;
  }
}