@import '../../../../scss/base.module';

.home-card-multilisting-stack {
  width: 100%;
  padding: 0 $spacing-m;

  &--rect {
    display: block;

    @include from-laptop {
      display: none;
    }
  }

  &--rounded {
    display: none;

    @include from-laptop {
      display: block;
    }
  }
}
