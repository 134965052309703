@import '../../../../scss/base.module';

$time: 120ms;
$easing: ease-in-out;

.home-card {
  @include font-s;
  position: relative;
  display: block;
  text-decoration: none;
  transition: box-shadow $time $easing;
  -webkit-tap-highlight-color: transparent;

  & > a {
    text-decoration: none;
  }

  &--selected {
    box-shadow: 0px 0px 0px 8px $interaction;
  }

  &--image-zoom {
    &:hover {
      img {
        transform: scale(1.08);
      }
    }
  }

  &--horizontal {
    flex-direction: row;
  }

  &__multilisting-stack {
    height: $multilisting-height-mobile;

    @include from-laptop {
      height: $multilisting-height-desktop;
    }
  }
}
